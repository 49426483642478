import { createEmailCookie } from "@/app/actions/tally/actions";

const redirectWithEmail = async (path: string = "/", email: string) => {
	if (email) {
		await createEmailCookie(email);
	}

	window.location.href = path;
};

export { redirectWithEmail };
