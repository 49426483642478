"use client";

import { FeedbackFish as FeedbackFishComponent } from "@feedback-fish/react";
import { MessageSquareText } from "lucide-react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";

import { ClientOnly as ClientOnlyComponent } from "@/components/client-only";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { useUser } from "@/lib/hooks/auth";
import { cn } from "@/lib/utils";

interface FeedbackFishWidgetProps extends React.HTMLAttributes<HTMLDivElement> {
	variant?: "primary" | "outline";
	showOnMobile?: boolean;
	asChild?: boolean;
	children: React.ReactNode;
}

const FeedbackFishWidget = ({
	children = "Send feedback",
	asChild = false,
	showOnMobile = false,
	className,
	variant = "primary",
}: FeedbackFishWidgetProps) => {
	const feedbackFishId = process.env.NEXT_PUBLIC_FEEDBACK_FISH_ID || "";
	const searchParams = useSearchParams();
	const { user } = useUser();
	const pathname = usePathname();
	const params = `?${searchParams.toString()}`;
	const isMobile = useMediaQuery("(max-width: 768px)", { defaultValue: true });
	const router = useRouter();

	const handleFeedbackClick = useCallback(() => {
		if (!user) {
			toast({
				title: "Login Required",
				description: "You must log in to submit feedback.",
				variant: "warning",
			});
			router.push(`/login?return_url=${encodeURIComponent(pathname + params)}`);
		}
	}, [user, router, pathname, params]);

	const buttonContent = useMemo(
		() =>
			asChild && typeof children != "string" ? (
				children
			) : (
				<Button
					variant={variant}
					size="md"
					className={cn(
						`mt-4 w-full justify-center gap-1 gap-x-2 whitespace-nowrap rounded-full px-4 py-2 text-center font-bold xl:px-5 xl:py-2`,
						className
					)}
				>
					<MessageSquareText className="h-4 w-4 lg:h-5 lg:w-5" />
					{(!isMobile || showOnMobile) && children}
				</Button>
			),
		[variant, asChild, className, children, showOnMobile, isMobile]
	);

	const button = useMemo(
		() => <div onClick={handleFeedbackClick}>{buttonContent}</div>,
		[buttonContent, handleFeedbackClick]
	);

	if (!feedbackFishId) return;

	return (
		<ClientOnlyComponent>
			{user ? (
				<FeedbackFishComponent
					projectId={feedbackFishId}
					userId={user.email}
					metadata={{ path: pathname + params, user: user.email }}
				>
					{button}
				</FeedbackFishComponent>
			) : (
				button
			)}
		</ClientOnlyComponent>
	);
};

export default FeedbackFishWidget;
